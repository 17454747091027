import { useEffect } from 'react';
import {useRouter} from 'next/router';
import * as Fathom from 'fathom-client';

import '../styles/index.css'

function MyApp({ Component, pageProps }) {
  /* Bugsnag.start({
    apiKey: 'bf3b4d2108cc304ad179d4766d1cc43d',
    plugins: [new BugsnagPluginReact()]
  });

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React); */

  const FATHOM_TRACKING_CODE = process.env.NEXT_PUBLIC_FATHOM_TRACKING_CODE;
  const router = useRouter();

  useEffect(() => {
    // Initialize Fathom when the app loads
    // Example: yourdomain.com
    //  - Do not include https://
    //  - This must be an exact match of your domain.
    //  - If you're using www. for your domain, make sure you include that here.
    Fathom.load(FATHOM_TRACKING_CODE, {
      includedDomains: ['pay.magpie.im'],
    });

    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, []);
  
  return <Component {...pageProps} />;
}

export default MyApp
